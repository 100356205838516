import * as React from 'react';
import { useGetClientCompaniesQuery } from 'src/services/api/companiesApi';
import { useAppSelector } from 'src/hooks/useStore';
import { selectCurrentUser } from 'src/store/storeUtils';
import { Button, Menu, MenuItem } from '@material-ui/core';

export const CompanySwitcher = () => {
  const [open, setOpen] = React.useState(false);
  const [currentLabel, setCurrentLabel] = React.useState('');
  const user = useAppSelector(selectCurrentUser);
  const { data } = useGetClientCompaniesQuery();
  const anchorEl = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (user) {
      setCurrentLabel(`${user.fields.givenName} ${user.fields.familyName}`);
    }
  }, [user]);

  return (
    <div>
      <Button ref={anchorEl} onClick={() => setOpen(true)}>
        {currentLabel}
      </Button>
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl.current}
      >
        {data?.map((company) => (
          <MenuItem
            key={company.id}
            onClick={() => {
              setCurrentLabel(company.fields.name);
              setOpen(false);
            }}
          >
            {company.fields.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
