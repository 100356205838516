import {
  primaryDomainHostname,
  clientDomainHostname,
} from 'src/constants/hostnameConsts';

export const copilotDashboardSubdomain = 'dashboard';

// copilot hostnames
export const copilotDashboardHostnameProd = `${copilotDashboardSubdomain}.${primaryDomainHostname.prod}`;
export const copilotDashboardHostnameStaging = `${copilotDashboardSubdomain}.${primaryDomainHostname.staging}`;
export const copilotAppDashboardHostnameProd = `${copilotDashboardSubdomain}.${clientDomainHostname.prod}`;
export const copilotAppDashboardHostnameStaging = `${copilotDashboardSubdomain}.${clientDomainHostname.staging}`;

export const prodClientUserDomain = clientDomainHostname.prod;
export const devClientUserDomain = clientDomainHostname.staging;

export const copilotClientUserDomains = [
  prodClientUserDomain,
  devClientUserDomain,
  '127.0.0.1',
];
