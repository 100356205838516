import * as React from 'react';
import { Auth } from 'aws-amplify';
import { FormikHelpers } from 'formik';
import { MFA_SETUP_TITLE } from 'src/constants';
import { FormModal } from 'src/legacy/components/Modals';
import { PortalConfigContext } from 'src/context';
import { INVALID_CODE } from 'src/constants/authConsts';
import { CognitoUser } from 'src/store/user/types';
import { useAppSelector } from 'src/hooks/useStore';
import { useMfaEnforcementForm } from 'src/legacy/components/UI/Pages/MfaEnforcementPage/useMfaEnforcementForm';
import { useSetMfaPreferenceMutation } from 'src/services/api/mfaEnforcementApi';
import { primaryDomainHostname } from 'src/constants/hostnameConsts';
import { capitalize } from 'src/utils/StringUtils';
export type SetupMfaState = 'SHOW_QR_CODE' | 'OTP_INPUT';

export interface VerifyMfaValues {
  challengeResponse: string;
  mfaSetupState: SetupMfaState;
  qrCode: string;
}

export const MfaEnforcementPage = () => {
  const userRef = React.useRef<CognitoUser>();
  const [totp, setTotp] = React.useState('');
  const [setMfaPreference] = useSetMfaPreferenceMutation();

  React.useEffect(() => {
    (async () => {
      const user = await Auth.currentAuthenticatedUser();
      userRef.current = user;

      const code = await Auth.setupTOTP(userRef.current);
      setTotp(code);
    })();
  }, [userRef]);

  // represents the state of the modal
  // there can be two states:
  // - scan qr code
  // - take otp input
  //
  // when, the user presses Next from the qr code
  // mfaSetupState changes from SHOW_QR_CODE to OTP_INPUT
  const [mfaSetupState, setMfaSetupState] =
    React.useState<SetupMfaState>('SHOW_QR_CODE');

  // ref to the form containing the inputs, this is used to show
  // validation errors
  const formRef = React.useRef<FormikHelpers<VerifyMfaValues>>();

  // this info is required to generate the mfa issuer
  const { name: portalName } = React.useContext(PortalConfigContext);
  const isClient = useAppSelector((state) => state.user.isClient);

  const qrCode = React.useMemo(() => {
    if (!totp || !userRef.current) {
      return '';
    }
    // the mfa issuer name, which is the app name the user sees in their
    // authenticator app for clients, this is the portal name for internal
    // users, we show Copilot
    const issuer = isClient
      ? portalName
      : capitalize(primaryDomainHostname.prod);
    return `otpauth://totp/${issuer}:${userRef?.current?.attributes.email}?secret=${totp}&issuer=${issuer}`;
  }, [portalName, isClient, totp]);

  // this is called whenever the primary action button is called for the
  // mfa setup modal
  const handleSave = async (values: VerifyMfaValues) => {
    // when we press next from qr code page
    // the state should transition to OTP_INPUT
    if (mfaSetupState === 'SHOW_QR_CODE') {
      // this causes the form hook to render the otp component
      setMfaSetupState('OTP_INPUT');
      return;
    }

    // if the challengeResponse was not specified then we can't verify the code
    if (!values.challengeResponse) {
      formRef?.current?.setFieldError('challengeResponse', INVALID_CODE);
      return;
    }

    try {
      // we have a input for otp, so we verify that and set the user's mfa method
      await Auth.verifyTotpToken(userRef.current, values.challengeResponse);
      // This saves the preference to dynamo, the back-end persists it to cognito as well.
      const result = await setMfaPreference({
        mfaPreference: 'SOFTWARE_TOKEN_MFA',
      });

      if ('error' in result) {
        // This is just here to execute the catch below.
        throw new Error('Failed to set MFA preference');
      }

      window.location.reload();
    } catch (e) {
      // if we fail to verify the code, error is set for the otp input field
      formRef?.current?.setFieldError('challengeResponse', INVALID_CODE);
    }
  };

  return (
    <>
      <FormModal
        useFormHook={useMfaEnforcementForm}
        initialFormValue={{
          // this is used to manage the state of the modal
          mfaSetupState,
          // this is used to store the uri as received from amplify for setting up mfa
          qrCode,
          challengeResponse: '',
        }}
        open={true}
        title={MFA_SETUP_TITLE}
        buttonLabel={mfaSetupState === 'OTP_INPUT' ? 'Verify Token' : 'Next'}
        handleSave={handleSave}
        onClose={() => {}}
        skipResetOnSubmit
        formRef={formRef}
        noCancelButton={true}
      />
    </>
  );
};
