import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Logo, { useStyles } from 'src/legacy/components/Logo';
import { PortalConfigContext, SidebarThemeContext } from 'src/context';
import { useGetPortalConfigQuery } from 'src/services/api';
import { TruncatedText } from 'src/legacy/components/UI';
import { typography13MediumStyle } from 'src/legacy/components/Text';
import { RootState } from 'src/store';
import { SidebarLogoMenu } from 'src/legacy/components/Sidebar/SidebarLogoMenu';
import { useWorkspaces } from 'src/hooks/useWorkspaces';
import { AuthenticatedPortalDataType } from 'src/legacy/components/Onboarding/Login/onboardingLoginTypes';
import { getCompanyId } from 'src/utils/UserUtils';

type Props = {
  isSettingsPage: boolean;
};

export const SidebarLogo = ({ isSettingsPage }: Props) => {
  const { sidebarTextColor } = React.useContext(SidebarThemeContext);
  const portalConfig = React.useContext(PortalConfigContext);
  const { data: config, isLoading: isPortalConfigLoading } =
    useGetPortalConfigQuery();
  const { user, companies, users } = useSelector(
    (state: RootState) => ({
      user: state.user,
      users: state.users.users,
      companies: state.clients?.activeCompanies ?? [],
    }),
    shallowEqual,
  );
  const classes = useStyles();
  const { getWorkspaces } = useWorkspaces();

  const [workspaces, setWorkspaces] = React.useState<
    Record<string, Record<string, AuthenticatedPortalDataType>>
  >({});

  const getWorkspacesList = async () => {
    const workspacesData = await getWorkspaces();
    setWorkspaces(workspacesData.workspaces);
  };
  React.useEffect(() => {
    // load workspaces list only if auth v2 is enabled
    // and it is internal user experience
    if (portalConfig.viewMode === 'internal') {
      getWorkspacesList();
    }
  }, []);

  const isCompaniesEnabled = !config?.structFields?.disableCompanies;
  const isInternalUserView = portalConfig.viewMode === 'internal';

  const showDefaultLogo =
    isInternalUserView || // Internal users see the default logo
    !isCompaniesEnabled || // If companies are not enabled, the default logo will be shown
    !companies.length || // If user is not associated with any companies, the default logo will be shown
    !portalConfig?.brand?.allowClientBranding; // No branding if feature isn't turned on

  const getLogoProps = () => {
    const userCompanyId = getCompanyId(user.id, users); // get logged in user's company id
    const company = companies.find((x) => x.id === userCompanyId); // get company details using company id
    return {
      logo: company?.fields?.avatarImageURL ?? '',
      name: company?.fields?.name ?? '',
      fallbackColor: company?.fields?.fallbackColor ?? '',
      isPlaceHolderCompany: company?.fields?.isPlaceholder ?? false,
    };
  };

  const { logo, name, isPlaceHolderCompany, fallbackColor } = getLogoProps();

  const hasValidImageURL = logo && !isPlaceHolderCompany;

  if (isSettingsPage) return null; // show nothing when it is settings page, or users or companies are not loaded yet.
  if (isPortalConfigLoading)
    // show skeleton while loading portal config or users
    return <Skeleton variant="rect" width="100%" height={28} />;

  return (
    <SidebarLogoMenu workspaces={workspaces}>
      {showDefaultLogo ? (
        <Logo />
      ) : (
        <div className={classes.logoWrapper}>
          <Avatar
            variant="rounded"
            className={
              hasValidImageURL
                ? classes.logoContainer
                : classes.noCompanyLogoContainer
            }
            style={{
              backgroundColor: !hasValidImageURL ? fallbackColor : '', // for company having no logo set avatar background color to company's fallbackColor
            }}
          >
            {hasValidImageURL ? (
              <img className={classes.logo} src={logo} alt="Logo" />
            ) : (
              <div>{name.split('')[0]}</div>
            )}
          </Avatar>

          <TruncatedText
            showTitle
            styleProps={{
              ...typography13MediumStyle,
              color: sidebarTextColor,
            }}
            text={name}
            maxChars={16.5}
          />
        </div>
      )}
    </SidebarLogoMenu>
  );
};
