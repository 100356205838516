import React, { useMemo } from 'react';
import BaseTypography from 'src/legacy/components/Text/BaseTypography';
import { useResizeObserver, Rect } from 'src/hooks/useResizeObserver';
import type { ChartData } from 'src/types/analytics';

// This is not exact, I kind of just tweaked it
// until things looked right.
const LABEL_WIDTH = 150;

function getDate(date: Date): string {
  return date.toLocaleDateString(undefined, {
    month: 'short',
    day: '2-digit',
  });
}

function getAxisLabels(data: ChartData[], rect: Rect): string[] {
  if (!data.length || !rect.width) {
    return [];
  }

  // Determine how many labels we can fit
  const maxLabels = Math.max(2, Math.floor(rect.width / LABEL_WIDTH));

  const firstItem = data.at(0);
  const lastItem = data.at(-1);

  if (!firstItem || !lastItem) {
    return [];
  }

  // Always include first and last dates
  const firstDate = getDate(firstItem.date);
  const lastDate = getDate(lastItem.date);

  // If we only have space for 2 labels or less, just return first and last
  if (maxLabels <= 2 || data.length <= 2) {
    return [firstDate, lastDate];
  }

  // Calculate how many middle labels we need
  const middleLabelsCount = maxLabels - 2;
  const step = (data.length - 1) / (middleLabelsCount + 1);

  // Generate middle labels
  const middleLabels = Array.from({ length: middleLabelsCount }, (_, i) => {
    const index = Math.round(step * (i + 1));
    return getDate(data[index].date);
  });

  // Return all labels
  return [firstDate, ...middleLabels, lastDate];
}

export function XAxisLabel({ data }: { data: ChartData[] }) {
  const { ref: sizeRef, rect } = useResizeObserver<HTMLDivElement>();
  const labels = useMemo(() => getAxisLabels(data, rect), [data, rect]);
  return (
    <div
      ref={sizeRef}
      className={[
        'w-full',
        'h-5',
        'text-gray-500',
        'overflow-visible',
        'flex',
        'flex-row',
        'justify-between',
      ].join(' ')}
    >
      {labels.map((label) => (
        <div key={label} className="text-gray-500">
          <BaseTypography fontType="13Regular">{label}</BaseTypography>
        </div>
      ))}
    </div>
  );
}
