import React from 'react';
import {
  Grid,
  makeStyles,
  Theme,
  createStyles,
  Button,
  IconButton,
  Popover,
} from '@material-ui/core';
import axios from 'axios';
import { SidebarListItem } from 'src/legacy/components/Sidebar/SidebarListItem';
import RowDivider from 'src/legacy/components/RowDivider';
import BaseTypography, {
  typography12MediumStyle,
} from 'src/legacy/components/Text/BaseTypography';
import {
  GraySmall,
  BlackHeadings,
  orange,
  green,
  WebLink,
} from 'src/theme/colors';
import { CLIENT_EXPERIENCE_ITEM, DOMAIN_PAGE } from 'src/constants';
import { PortalConfigContext } from 'src/context';
import { StatusIcon } from 'src/legacy/components/Icons/StatusIcon';
import { CopyIcon, QuestionIcon } from 'src/legacy/components/Icons';
import { Callout } from 'src/legacy/components/Callout';
import CopyToClipboard from 'src/legacy/components/CopyToClipboard';
import useNavigate from 'src/hooks/useNavigate';
import { DescriptionTooltip } from 'src/legacy/components/Tooltip';
import * as Colors from 'src/theme/colors';
import { UrlUtils } from 'src/utils';
import { useCustomDomains } from 'src/hooks/useCustomDomains';
import { useAppDispatch } from 'src/hooks/useStore';
import { togglePrimarySidebarMobile } from 'src/store/ui/actions';
import { primaryDomainHostname } from 'src/constants/hostnameConsts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
    },
    nested: {
      padding: theme.spacing(0.5, 0, 0.5, 3),
    },
    sidebarItemDivider: {
      padding: theme.spacing(0, 1),
      '& hr': {
        backgroundColor: Colors.NonHoverBorder,
      },
    },
    menuList: {
      padding: theme.spacing(1),
      paddingBottom: 0,
      minWidth: 170,
      outline: 'none',
    },
    popover: {
      zIndex: theme.zIndex.speedDial,
      '& .MuiPopover-paper': {
        overflowX: 'unset',
        overflowY: 'unset',
      },
      margin: theme.spacing(-1),
    },
    copyText: {
      wordBreak: 'break-all',
      color: BlackHeadings,
    },
    clientLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    tooltip: {
      color: Colors.BlackHeadings,
    },
    buttonContainer: {
      ...typography12MediumStyle,
      minWidth: 'unset',
      verticalAlign: 'baseline',
      marginRight: theme.spacing(0.5),
      color: WebLink,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    clientExperienceHeader: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: theme.spacing(0.5),
    },
    questionIcon: {
      display: 'flex',
    },
    popoverTittle: {
      display: 'flex',
    },
    copyIcon: {
      fontSize: 12,
    },
    iconButton: {
      padding: 'unset',
    },
  }),
);

const HealthCheckPassThreshold = 2;

export const ClientExperienceNavItem = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { clientExperienceEnabled } = React.useContext(PortalConfigContext);
  const { domains, isCustomDomainConnected } = useCustomDomains();
  const { navigate } = useNavigate();
  const activeDomain =
    domains.find(
      (x) => x.id !== 'default' && x.domainMetadata?.status === 'SUCCESS',
    ) ?? domains.at(0);
  const clientExperienceLink = `${activeDomain?.domainMetadata?.subdomain}.${activeDomain?.domainMetadata?.domain}`;

  const classes = useStyles();

  // timer for health check polling
  const healthCheckTimer = React.useRef(0);
  // counter for health check polling, use ref to allow for async updates in the timer
  const healthCheckPassCount = React.useRef(0);

  const isDomainReady =
    clientExperienceEnabled ||
    healthCheckPassCount.current >= HealthCheckPassThreshold;

  /**
   * run a health check on the client experience link and create
   * a timer to check again if not healthy
   */
  const runHealthCheck = async () => {
    try {
      const response = await axios.get(
        UrlUtils.GetFullUrl(`${clientExperienceLink}/health`),
      );
      if (response.status === 200 && response.data === 'Healthy') {
        // increment the pass count
        healthCheckPassCount.current += 1;
        // if we have passed the threshold, clear the timer and return
        if (healthCheckPassCount.current > HealthCheckPassThreshold) {
          if (healthCheckTimer.current) {
            clearInterval(healthCheckTimer.current);
          }
          return;
        }
      }
    } catch (e) {
      // do nothing
    }

    // continue to check with a delay
    healthCheckTimer.current = window.setTimeout(() => {
      runHealthCheck();
    }, 2000);
  };

  React.useEffect(() => {
    // if client experience is not enabled and we have an active domain, run the health check
    // this is used to verify if the domain is ready preemptively before the backend has finished
    // a more robust health check across all domains
    if (!clientExperienceEnabled && activeDomain) {
      runHealthCheck();
    }
  }, [clientExperienceEnabled, activeDomain]);

  /**
   * clear the health check timer on unmount if its set
   */
  React.useEffect(
    () => () => {
      if (healthCheckTimer.current) {
        clearInterval(healthCheckTimer.current);
      }
    },
    [],
  );

  const closePopover = () => {
    setAnchorEl(null);
  };

  const navigateToDomainSettings = () => {
    // close main mobile sidebar before navigating to the settings page
    dispatch(
      togglePrimarySidebarMobile({
        isOpen: false,
      }),
    );
    navigate(DOMAIN_PAGE.path);
  };

  const openClientExperience = () => {
    window.open(UrlUtils.GetFullUrl(clientExperienceLink), '_blank');
  };
  return (
    <>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        className={classes.popover}
      >
        <Grid container direction="column" style={{ width: 300 }}>
          <Grid item style={{ padding: '12px 12px 0px 12px' }}>
            <BaseTypography
              data-testid="client-experience-header"
              fontType="15Medium"
              className={classes.popoverTittle}
            >
              <div>
                {isDomainReady
                  ? 'Your portal is active'
                  : 'Your portal is not ready yet'}
              </div>
              <div>
                <StatusIcon
                  style={{
                    color: isDomainReady ? green : orange,
                    width: 8,
                    height: 'unset',
                    paddingLeft: 8,
                  }}
                />
              </div>
            </BaseTypography>

            <BaseTypography
              data-testid="client-experience-subheader"
              fontType="12Regular"
              style={{ color: GraySmall }}
            >
              {isDomainReady
                ? 'All services are operational'
                : 'Your portal is currently being set up and will be ready in a few minutes'}
            </BaseTypography>
          </Grid>
          {!isCustomDomainConnected && (
            <Grid item>
              <RowDivider mt={1.5} mb={1.5} />
              <Grid item style={{ padding: '0px 12px 0px 12px' }}>
                <BaseTypography fontType="12Medium">
                  Custom Domain
                </BaseTypography>
                <BaseTypography
                  fontType="12Regular"
                  style={{ color: GraySmall }}
                >
                  <Button
                    onClick={navigateToDomainSettings}
                    className={classes.buttonContainer}
                  >
                    Click here
                  </Button>
                  to add a custom domain
                </BaseTypography>
              </Grid>
            </Grid>
          )}

          <RowDivider mt={1.5} mb={1.5} />
          <Grid item style={{ padding: '0px 12px 16px 12px' }}>
            <BaseTypography
              fontType="12Medium"
              className={classes.clientExperienceHeader}
            >
              <div>Portal</div>
              <DescriptionTooltip
                title={
                  <BaseTypography
                    fontType="13Medium"
                    className={classes.tooltip}
                  >
                    You’re currently in the admin experience (dashboard.$
                    {primaryDomainHostname.prod}) which is only available to
                    your team members. The client experience is where your
                    clients can log in. It is available on the URL below.
                  </BaseTypography>
                }
              >
                <span className={classes.questionIcon}>
                  <QuestionIcon
                    style={{ fontSize: 13, color: GraySmall, paddingLeft: 3 }}
                  />
                </span>
              </DescriptionTooltip>
            </BaseTypography>
            <Callout hideIcon>
              <div className={classes.clientLinkContainer}>
                <BaseTypography
                  className={classes.copyText}
                  fontType="12Regular"
                >
                  {clientExperienceLink}
                </BaseTypography>
                <CopyToClipboard>
                  {({ copy }) => (
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => copy(clientExperienceLink)}
                    >
                      <CopyIcon className={classes.copyIcon} />
                    </IconButton>
                  )}
                </CopyToClipboard>
              </div>
            </Callout>
          </Grid>
          <Grid item style={{ padding: '0px 12px 20px 12px' }}>
            <Button
              style={{ width: '100%', height: 40 }}
              variant="contained"
              color="secondary"
              onClick={openClientExperience}
            >
              Open portal
            </Button>
          </Grid>
        </Grid>
      </Popover>
      <div className={classes.sidebarItemDivider}>
        <RowDivider mt={1} mb={1} />
      </div>
      <SidebarListItem
        htmlId="sidebar-client-experience"
        label={CLIENT_EXPERIENCE_ITEM.label}
        IconElement={CLIENT_EXPERIENCE_ITEM.icon}
        iconStyleProps={{ color: isDomainReady ? green : orange }}
        handleClick={(event) => {
          if (event?.currentTarget instanceof HTMLElement) {
            setAnchorEl(event.currentTarget);
          }
        }}
        selected={Boolean(anchorEl)}
      />
    </>
  );
};
